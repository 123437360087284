import {
  Alert,
  Button,
  Center,
  Divider,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  TextInput,
  Title
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconCheck, IconMail, IconAlertCircle, IconLock } from '@tabler/icons'
import { getSession, signIn } from 'next-auth/react'
import Head from 'next/head'
import Image from 'next/image'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import Layout from '../components/layout'
import { useRouter } from 'next/router'

const Login = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [oauthError, setOauthError] = useState('')
  const toasterShown = useRef(false)

  const router = useRouter()
  const { error, fromPricing } = router.query

  useEffect(() => {
    if (error === 'OAuthAccountNotLinked') {
      setOauthError(
        'Your account is already linked with another provider. Please sign in with that provider instead.'
      )
    }

    if (fromPricing === 'true' && !toasterShown.current) {
      toasterShown.current = true
      notifications.show({
        title: 'Inicio de sesión requerido',
        message: 'Para acceder a nuestros planes, por favor inicia sesión',
        color: 'grape',
        icon: <IconLock size={16} />,
        autoClose: 3000
      })
    }
  }, [error, fromPricing])

  const handleEmailSignIn = async () => {
    setLoading(true)
    try {
      await signIn('email', {
        email,
        callbackUrl: '/profile',
        redirect: false
      })
      setAlert(true)
      setLoading(false)
      setEmail('')
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  const handleGoogleSignIn = () => {
    signIn('google', {
      callbackUrl: '/profile'
    })
  }

  return (
    <>
      <Head>
        <title>Login/Sign In - Stream Discover</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Center sx={{ height: '100%' }}>
        <Paper
          w={350}
          p="xl"
          sx={{
            backgroundColor: 'rgba(153, 139, 165, 0.12)',
            backdropFilter: 'blur(100px)',
            borderRadius: '10px'
          }}
        >
          <Stack align="center">
            <Image
              src="/stream_discover_logo.png"
              width={160}
              height={22}
              alt="logo"
              style={{
                marginTop: 0,
                marginBottom: 10
              }}
            />
            <Stack spacing={16}>
              <Title order={3}>Sign In</Title>
              <Text fz="sm" c="dimmed">
                Enter your email to log in or create an account.
              </Text>
              <TextInput
                icon={<IconMail />}
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
                aria-label="Email Address"
                placeholder="example@mail.com"
                className="login-input"
                styles={(theme) => ({
                  input: {
                    fontSize: theme.fontSizes.sm,
                    backgroundColor: '#121E274D',
                    padding: '4px 8px',
                    borderRadius: '10px',
                    border: 'none',
                    borderBottom: '2px solid #7B39B6',
                    opacity: 1,
                    position: 'relative'
                  },
                  item: {
                    fontSize: theme.fontSizes.xs
                  }
                })}
              />
              <Button
                variant="gradient"
                gradient={{
                  from: '#7B39B6',
                  to: '#7B39B6'
                }}
                radius="xl"
                onClick={handleEmailSignIn}
                disabled={loading}
                sx={{
                  width: '100%',
                  height: '40px'
                }}
              >
                {loading ? <Loader size={14} /> : 'Sign In'}
              </Button>

              {alert && (
                <Alert
                  icon={<IconCheck size="1rem" />}
                  title="Email sent!"
                  color="teal"
                >
                  Check your email for the sign in link. If you don&apos;t see it,
                  check your spam folder.
                </Alert>
              )}

              {oauthError && (
                <Alert
                  icon={<IconAlertCircle size="1rem" />}
                  title="Account Linked!"
                  color="red"
                >
                  Your account is already linked with another provider. Please sign
                  in with that provider instead.
                </Alert>
              )}

              <Divider label="OR" labelPosition="center" />
              <Group ml={15} position="center">
                <Button
                  leftIcon={<img src="/google_sign.png" width={50} height={50} />}
                  onClick={handleGoogleSignIn}
                ></Button>
              </Group>
            </Stack>
          </Stack>
        </Paper>
      </Center>
    </>
  )
}

Login.propTypes = {
  providers: PropTypes.object
}

export default Login

Login.getLayout = function getLayout(page) {
  return <Layout withAutocomplete>{page}</Layout>
}

export async function getServerSideProps(context) {
  const { req } = context
  const session = await getSession({ req })

  if (session) {
    return {
      redirect: { destination: '/' }
    }
  }

  return {
    props: {
      messages: (
        await import(`../locales/${context.locale.split('-')[0]}/common.json`)
      ).default
    }
  }
}
